var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-drawer", {
    attrs: {
      title: "客户详情",
      visible: _vm.visible,
      "before-close": _vm.handleCloseDrawer,
      size: "1080px",
      "show-close": "",
      "wrapper-closable": true,
      modal: _vm.drawerModal,
      "custom-class": "customer_drawer"
    }
  }, [_c("div", {
    staticClass: "drawer"
  }, [_c("div", {
    staticClass: "drawer_left"
  }, [_c("div", {
    staticClass: "drawer_left_info"
  }, [_c("div", {
    staticClass: "flex-row-start header"
  }, [_c("div", [_vm._v("客户ID：" + _vm._s(_vm.drawerForm.id))]), _c("div", {
    staticClass: "project"
  }, [_vm._v("项目：" + _vm._s(_vm.drawerForm.secondProjectName || "-"))]), _c("div", {
    staticClass: "project"
  }, [_vm._v(" 当前归属人：" + _vm._s(_vm.drawerForm.ownerName || "-") + " ")])]), _c("info-com", {
    attrs: {
      "can-edit": _vm.canEdit,
      "drawer-form": _vm.drawerForm
    },
    on: {
      refresh: _vm.handleRefresh
    }
  }), _vm.isOwner && !_vm.isFlowCustomerManage ? _c("div", {
    staticClass: "msg-and-btns"
  }, [_c("div", {
    staticClass: "msg-and-call"
  }, [_c("msg-invite-com", {
    attrs: {
      "c-id": _vm.cId
    }
  }), _c("phone-call-com", {
    attrs: {
      "c-id": _vm.cId,
      "contact-phone": _vm.drawerForm.contactPhone
    }
  })], 1), _c("btns-com", {
    attrs: {
      "c-id": _vm.cId,
      "drawer-form": _vm.drawerForm,
      "right-com-data": _vm.rightComData
    },
    on: {
      submitSuccessed: _vm.closeDrawer,
      refresh: _vm.handleRefresh
    }
  })], 1) : _vm._e()], 1), _c("div", {
    staticClass: "drawer_left_record"
  }, [_c("log-com", {
    attrs: {
      "c-id": _vm.cId,
      "ai-error-reason-opt": _vm.aiErrorReasonOpt
    }
  })], 1)]), _c("right-com", {
    attrs: {
      "c-id": _vm.cId,
      "can-edit": _vm.canEdit,
      "form-data": _vm.rightComData,
      "selected-label": _vm.labelList,
      "crm-label": _vm.crmLabel,
      info: _vm.clockInfo,
      "ai-error-reason-opt": _vm.aiErrorReasonOpt
    },
    on: {
      submitSuccess: _vm.handleRefresh
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };