import { reactive } from 'vue';
export default {
  name: 'AiCallCom',
  setup: function setup(xy, _ref) {
    var attrs = _ref.attrs;
    var dialog = reactive({
      isExist: false,
      visible: false
    });
    var handleDialog = function handleDialog() {
      !dialog.isExist && (dialog.isExist = !dialog.isExist);
      dialog.visible = !dialog.visible;
    };
    var senderType = {
      0: '客户',
      1: '机器人'
    };
    var senderType1 = {
      0: '说话人1',
      1: '说话人2'
    };
    var getSender = function getSender(type) {
      return attrs.type === 10 ? senderType1[type] : senderType[type];
    };
    var getClass = function getClass(type) {
      if (type) {
        return 'right';
      } else {
        return 'left';
      }
    };
    return {
      dialog: dialog,
      handleDialog: handleDialog,
      attrs: attrs,
      getSender: getSender,
      getClass: getClass
    };
  }
};