import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "min-com"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "main"
  }, [_c("span", {
    staticClass: "bold"
  }, [_vm._v(" " + _vm._s(_vm.getTitle()) + " ")]), _vm.item.type === 11 ? _c("span", {
    staticClass: "min-com-ai"
  }, [_vm._v("AI")]) : _vm._e(), ![10, 11].includes(_vm.item.type) ? _c("span", {
    staticClass: "content"
  }, [_vm._v(" " + _vm._s(_vm.item.detail) + " ")]) : _c("span", {
    staticClass: "call"
  }, [_c("callPlayerCom", {
    attrs: {
      src: _vm.item.detail
    }
  }), [10, 11].includes(_vm.item.type) ? _c("ai-call-com", {
    attrs: {
      type: _vm.item.type,
      "msg-list": _vm.item.msgList,
      summary: _vm.item.summary
    }
  }) : _vm._e()], 1)]), _vm.aiLabels && _vm.aiLabels.length ? _c("div", {
    staticClass: "min-com-ai-label"
  }, [_c("div", [_vm._v("AI标签：")]), _vm._l(_vm.aiLabels, function (el) {
    return _c("ai-tag-com", {
      key: el.id,
      attrs: {
        "customer-id": _vm.cId,
        "customer-label": el,
        "ai-error-reason-opt": _vm.aiErrorReasonOpt
      },
      on: {
        refreshAiTag: function refreshAiTag($event) {
          return _vm.refreshAiTag(el.id);
        }
      }
    });
  })], 2) : _vm._e(), _c("div", {
    staticClass: "bottom"
  }, [_vm._v(" 操作者：" + _vm._s(_vm.item.createBy || _vm.item.updatedBy) + " ")])]), _c("div", {
    staticClass: "bottom time"
  }, [_vm._v(" " + _vm._s(_vm.item.createTime) + " ")])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };