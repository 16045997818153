import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import callPlayerCom from '@/components/callPlayerCom';
import AiCallCom from './aiCallCom.vue';
import aiTagCom from '../rightCom/aiTagCom.vue';
export default {
  name: 'MinCom',
  components: {
    callPlayerCom: callPlayerCom,
    AiCallCom: AiCallCom,
    aiTagCom: aiTagCom
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    cId: {
      type: Number,
      required: true
    },
    aiErrorReasonOpt: {
      type: Array,
      required: true
    }
  },
  data: function data() {
    return {
      chatRecordType: [{
        id: 0,
        name: '新增客户：'
      }, {
        id: 1,
        name: '呼出呼入录音：'
      }, {
        id: 2,
        name: '来访记录：'
      }, {
        id: 3,
        name: '跟进备注：'
      }, {
        id: 4,
        name: '意向反馈：'
      }, {
        id: 5,
        name: '意向度评价：'
      }, {
        id: 6,
        name: '订单记录：'
      }, {
        id: 7,
        name: '用户基本信息变更：'
      }, {
        id: 8,
        name: '名片来源变更：'
      }, {
        id: 9,
        name: '短信邀请：'
      }, {
        id: 10,
        name: '电话呼叫：'
      }, {
        id: 11,
        name: '智能外呼'
      }],
      aiLabels: this.item.aiLabels
    };
  },
  methods: {
    getTitle: function getTitle() {
      var item = this.item,
        chatRecordType = this.chatRecordType;
      if (typeof item.type === 'number') {
        return chatRecordType.filter(function (type) {
          return type.id === item.type;
        })[0].name;
      }
    },
    refreshAiTag: function refreshAiTag(id) {
      this.aiLabels = this.aiLabels.filter(function (item) {
        return item.id !== id;
      });
    }
  }
};