import "core-js/modules/es.array.concat.js";
import request from '@/utils/request';
var BASE_URL = 'api/customers';

/**
 * @description 客户详情组件 咨询历史
 * @param  {number} id
 * @param  {1 | 2} sort 升降序，1为降序，2为升序
 */
export function consultHistory(id, sort) {
  return request({
    url: "".concat(BASE_URL, "/").concat(id, "/consultHistory?sort=").concat(sort),
    method: 'GET'
  });
}

/**
 * @description 客户详情组件 跟进记录
 * @param  {number} id
 * @param  {1 | 2} sort 升降序，1为降序，2为升序
 */
export function followLog(id, sort) {
  return request({
    url: "".concat(BASE_URL, "/").concat(id, "/followUpLog?sort=").concat(sort),
    method: 'GET'
  });
}

/**
 * @description 客户详情组件 信息变更记录
 * @param {Number} id 名片id
 */
export function infoUpdateLog(id, sort) {
  return request({
    url: "".concat(BASE_URL, "/").concat(id, "/info-update-log?sort=").concat(sort),
    method: 'GET'
  });
}

/**
 * @description 客户详情组件 订单交易
 * @param  {Number} id
 */
export function getRegistedList(id, sort) {
  return request({
    url: "".concat(BASE_URL, "/").concat(id, "/registration-record?sort=").concat(sort),
    method: 'GET'
  });
}

/**
 * @description 客户详情组件 流转日志
 * @param  {number} id
 * @param  {1 | 2} sort 升降序，1为降序，2为升序
 */
export function movingLog(id, sort) {
  return request({
    url: "".concat(BASE_URL, "/").concat(id, "/movingLog?sort=").concat(sort),
    method: 'GET'
  });
}

/**
 * @description 编辑单个客户（编辑名片）
 * @param  {Object} data
 * @param  {number} id
 * @param  {string} name
 * @param  {number} gender
 * @param  {number} edu_background
 * @param  {number} province
 * @param  {number} city
 * @param  {number} county
 */
export function editCustomer(data) {
  return request({
    url: "".concat(BASE_URL),
    method: 'PUT',
    data: data
  });
}

/**
 * @description 编辑评价
 * @param  {Object} data
 * @param  {Number} id
 * @param  {Array} contactPhone 手机号码
 * @param  {Array} contactWechat 微信号
 * @param  {Array} contactQq QQ号
 * @param {Number} evaluation 评价
 * @param {Number} intentionLevel 意向度
 * @param {Number} invalidTypeId 静默类型
 * @param  {Array} label 标签
 * @param {String} remark 跟进备注
 * @param {boolean} updateContact 是否修改联系方式 这个字段是为了后端不报错 因为一改请求太频繁
 */
export function editIntention(data) {
  return request({
    url: "".concat(BASE_URL, "/").concat(data.id, "/evaluations"),
    method: 'POST',
    data: data
  });
}

/**
 * @description 首页通过联系方式查看名片
 * @param  {string} num 联系号码
 */
export function getCards(num) {
  return request({
    url: "".concat(BASE_URL, "/listByPhoneOrWechat/").concat(num),
    method: 'GET'
  });
}

/**
 * @description 短信邀请
 * @param  {number} cId
 */
export function getMsg(cId) {
  return request({
    url: "".concat(BASE_URL, "/sendMessageList/").concat(cId),
    method: 'GET'
  });
}

/**
 * @description 发送短信邀请
 * @param  {object} data
 * @param  {number} id 	名片id
 * @param  {string} phone
 */
export function sendMsg(data) {
  return request({
    url: "".concat(BASE_URL, "/sendMessage"),
    method: 'POST',
    data: data
  });
}

/**
 * @description 拨打电话v6.7
 * @param  {object} data
 * @param  {number} id 	名片id
 * @param  {string} phone
 */
export function callPhone(data) {
  return request({
    url: 'api/traffic/callPhone',
    method: 'POST',
    data: data
  });
}

/**
 * @description 挂断呼叫v7.4.2
 */
export function cutCall() {
  return request({
    url: 'api/traffic/hangupCall',
    method: 'POST'
  });
}

/**
 * @description 保存AI反馈
 * @param  {object} data
 * @param  {number} customerId 客户id
 * @param  {number} customerLabelId AI标签id
 * @param  {number[]} errorReasonIds 错误原因id列表
 */
export function aiFeedback(data) {
  return request({
    url: 'api/aiFeedbacks',
    method: 'POST',
    data: data
  });
}

/**
 * @description 查询AI错误原因
 * @returns {Array}
 * @returns {number} id
 * @returns {string} reason
 * @returns {boolean} status
 */
export function getAiErrorReason() {
  return request({
    url: 'api/aiErrorReasons?status=true',
    method: 'GET'
  });
}