import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-tag", {
    staticClass: "ai-tag-com",
    attrs: {
      closable: "",
      effect: "plain"
    },
    on: {
      close: _vm.handleVisible
    }
  }, [_c("img", {
    staticClass: "ai-tag-com-img",
    attrs: {
      src: require("@/assets/svgs/ai_icon.svg"),
      alt: "ai_icon"
    }
  }), _c("div", [_vm._v(_vm._s(_vm.customerLabel.categoryName) + "：" + _vm._s(_vm.customerLabel.name))]), _vm.dialog.isExist ? _c("el-dialog", {
    attrs: {
      visible: _vm.dialog.visible,
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "append-to-body": true,
      title: "标签错误",
      width: "470px",
      "custom-class": "ai-tag-com-dialog"
    },
    on: {
      "update:visible": function updateVisible($event) {
        return _vm.$set(_vm.dialog, "visible", $event);
      }
    }
  }, [_c("el-form", {
    ref: "aiTagRef",
    attrs: {
      model: _vm.form,
      rules: _setup.rules,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "请选择错误原因，支持多选：",
      prop: "reasons"
    }
  }, [_c("el-checkbox-group", {
    model: {
      value: _vm.form.reasons,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reasons", $$v);
      },
      expression: "form.reasons"
    }
  }, _vm._l(_vm.aiErrorReasonOpt, function (item) {
    return _c("el-checkbox", {
      key: item.id,
      attrs: {
        label: item.id
      }
    }, [_vm._v(" " + _vm._s(item.reason) + " ")]);
  }), 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.handleVisible
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary",
      loading: _vm.dialog.loading
    },
    on: {
      click: _vm.handlesubmit
    }
  }, [_vm._v("确认")])], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };